<template>
  <div>
    <b-card>
      <b-form inline @submit="onSubmit" @reset="onReset">
        <h5 class="title">ส่วนค้นหาข้อมูล:</h5>
        <!-- หมายเลขสินค้า -->
        <b-input-group class="ml-1p">
          <b-input-group-prepend is-text>
            {{ c_username }}
          </b-input-group-prepend>
          <!-- Input -->
          <b-form-input
            id="product-number"
            placeholder="c_username"
            v-model="form.c_username"
          />
        </b-input-group>
        <b-button type="submit" variant="primary" class="ml-2p">
          {{ search }}
        </b-button>
        <b-button type="reset" variant="secondary" class="ml-1p">
          {{ reset }}
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  // Form
  BForm,
  BFormInput,
  // BFormGroup,
  BInputGroup,
  // Card
  BCard,
  // Button
  BButton,
  // Select
  BFormSelect,
  // Prepend
  BInputGroupPrepend,
} from "bootstrap-vue";

export default {
  components: {
    // Form
    BForm,
    BFormInput,
    // BFormGroup,
    BInputGroup,
    // Card
    BCard,
    // Button
    BButton,
    // Selected
    BFormSelect,
    // Prepend
    BInputGroupPrepend,
  },
  data() {
    return {
      // Form
      form: {
        c_username: "",
      },
      c_username: "ชื่อผู้ใช้งาน (Username)",
      search: "ค้นหา",
      reset: "ล้างค่า",
      searchBtn: "",
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$emit("SubmitData", this.form);
      // alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.c_code = "";
      this.form.c_accountName = "";
      this.form.c_username = "";
    },
  },
};
</script>

<style scoped>
.ml-1p {
  margin-left: 1%;
}
.ml-2p {
  margin-left: 2%;
}
.title{
  display: contents;
  font-weight: bold;
}
</style>
