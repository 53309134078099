<template>
  <b-row>
    <b-col cols="12">
      <account-table />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import accountTable from "./Table.vue";
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,

    accountTable,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
