<template>
  <b-card title="Dashboard">
    <b-overlay :show="loading" rounded="sm">
      <!-- search input -->
      <div>
        <SearchComponent @SubmitData="searchSubmit" />
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="accountList"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          //disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectionChanged"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-page-change="paginationFunc"
        @on-per-page-change="itemPerpageFunc"
        @on-sort-change="sortFunc"
      >
        <!-- Selected: Delete -->
        <div slot="selected-row-actions">
          <b-button variant="gradient-primary" @click="showModal"
            >เลือก</b-button
          >
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'b_symbol'" class="text-nowrap">
            <img
              :src="
                require(`@/assets/images/bank/${props.row.b_symbol
                  .replace(/\s/g, '')
                  .toLowerCase()}.png`)
              "
              width="30"
              style="margin-right:10px"
              v-if="
                props.row.b_symbol.replace(/\s/g, '').toLowerCase() ===
                  'kbank' ||
                  props.row.b_symbol.replace(/\s/g, '').toLowerCase() ===
                    'scb' ||
                  props.row.b_symbol.replace(/\s/g, '').toLowerCase() ===
                    'bay' ||
                  props.row.b_symbol.replace(/\s/g, '').toLowerCase() ===
                    'ktb' ||
                  props.row.b_symbol.replace(/\s/g, '').toLowerCase() === 'bbl'
              "
            />
            {{ props.row.b_symbol.replace(/\s/g, "").toUpperCase() }}
          </span>

          <!-- Column: Action -->
          <!-- <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                variant="gradient-primary"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'ดูรายละเอียด'"
                :to="`account-detail/${props.row.id}`"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                variant="gradient-warning"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'แก้ไข'"
                :to="`account-edit/${props.row.id}`"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                variant="gradient-success"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'เปิดใช้งาน'"
                v-if="props.row.use === false"
                @click="showOpenModal(props.row.id)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'ปิดใช้งาน'"
                v-if="props.row.use === true"
                @click="showCloseModal(props.row.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'ลบ'"
                @click="showDeleteModal(props.row.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </span>
          </span> -->

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- Column: loading -->
        <template slot="loadingContent">
          <b-spinner variant="primary" label="Text Centered" />
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="perPageValue"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ totalCount }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="pageValue"
                :total-rows="totalCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="modal-select"
        v-model="modalShow"
        centered
        title="เลือกธนาคาร"
      >
        <div class="modal-content-select">
          <b-form ref="form" @submit.stop.prevent="handleSubmit">
            <v-select
              v-model="selectedBank"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionBank"
              transition=""
              required
              :filter="optionSearch"
            >
              <template
                #option="{ b_symbol, b_name, BankAccountNumber, OwnName }"
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="
                      require(`@/assets/images/bank/${b_symbol.toLowerCase()}.png`)
                    "
                    class="mr-50"
                  ></b-avatar>
                  <div class="detail">
                    <b-card-text class="mb-0"
                      >{{ b_name }} {{ BankAccountNumber }} {{ OwnName }}
                    </b-card-text>
                  </div>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <b-avatar
                  size="sm"
                  :src="
                    require(`@/assets/images/bank/${option.b_symbol
                      .replace(/\s/g, '')
                      .toLowerCase()}.png`)
                  "
                  class="mr-50"
                ></b-avatar>
                <b-card-text class="mb-0"
                  >{{ option.b_name }} {{ option.b_symbol }}
                  {{ option.BankAccountNumber }}
                  {{ option.OwnName }}
                </b-card-text>
              </template>
            </v-select>
            <small v-if="selectError" style="color: red"
              >กรุณากรอกข้อมูลให้ถูกต้อง</small
            >
          </b-form>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="success"
              class="float-right"
              @click="handleOk"
              :disabled="btnLoading"
            >
              <span v-if="btnLoading"><b-spinner small />กำลังบันทึก</span>
              <span v-else>ยืนยัน</span>
            </b-button>
            <b-button
              variant="danger"
              class="float-right"
              @click="closeModal"
              style="margin-right: 2%"
              :disabled="btnLoading"
            >
              ยกเลิก
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
  VBTooltip,
  BCard,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
// import dataDummy from "./data";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BaseAPIURL from "@core/app-config/baseURL";
import perPageValue from "@/constants/perPageTableExisting";
import configFunction from "@/constants/functional";
import store from "@/store/index";
import SearchComponent from "../components/Search.vue";
import vSelect from "vue-select";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    SearchComponent,
    BOverlay,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    // vendorList: Array,
    // table: String,
    // where: String,
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      // API
      collectionAPI: "/users-existing", //ชื่อ API
      collectionBankAPI: "/bank-existing",
      whereLimit: "&_limit=", // condition  ตั้งต้น, ตัวอย่างการเขียน -> "&_limit=5",
      whereSortList: [],
      whereSortCondition: "",
      wherePagination: "",
      whereSearchCondition: "",
      whereQueryDefaultCondition: "", // Qeury เริ่มต้น, ตัวอย่างการเขียน -> "&delete=false",
      whereCondition: "",
      // Table
      pageLength: 0,
      pageValue: 1,
      dir: false,
      columns: [
        // {
        //   label: "c_code",
        //   field: "c_code",
        // },
        {
          label: "ชื่อผู้ใช้งานลูกค้า",
          field: "c_username",
        },
        {
          label: "ชื่อลูกค้า",
          field: "c_accountName",
        },
        {
          label: "เบอร์ติดต่อลูกค้า",
          field: "c_mobile",
        },
        {
          label: "ธนาคารโอนเข้า",
          field: "b_symbol",
        },
        // {
        //   label: "ธนาคารโอนเข้า",
        //   field: "b_name",
        // },
        {
          label: "เลขบัญชีโอนเข้า",
          field: "BankAccountNumber",
        },
        {
          label: "ชื่อบัญชีโอนเข้า",
          field: "OwnName",
        },
      ],
      optionBank: [],
      selectedBank: [],
      selectedRow: [],
      selectError: false,
      rows: [],
      searchTerm: "",
      modalShow: false,
      // accountList: dataDummy.accountList, // import from data Dummy
      accountList: [],
      totalCount: 0,
      moment,
      BaseAPIURL,
      perPageValue,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false;
        return this.dir;
      }
    },
  },
  created() {
    // Set Page Data
    this.pageLength = this.perPageValue[0];
    this.whereLimit = this.whereLimit + `${this.perPageValue[0]}`;
    // Get Data
    this.getDataTotal();
    this.fetchData();
    this.fetchOption();
  },
  methods: {
    selectionChanged(e) {
      this.selectedRow = {};
      this.selectedRow = e.selectedRows;
    },
    getDataTotal() {
      this.whereCondition = `${
        this.whereLimit && this.whereLimit !== "" ? this.whereLimit : ""
      }${
        this.whereSortCondition && this.whereSortCondition !== ""
          ? this.whereSortCondition
          : ""
      }${
        this.wherePagination && this.wherePagination !== ""
          ? this.wherePagination
          : ""
      }${
        this.whereSearchCondition && this.whereSearchCondition !== ""
          ? this.whereSearchCondition
          : ""
      }${
        this.whereQueryDefaultCondition &&
        this.whereQueryDefaultCondition !== ""
          ? this.whereQueryDefaultCondition
          : ""
      }`;

      return useJwt
        .query(this.collectionAPI, this.whereCondition)
        .then((res) => {
          console.log("res count", res.data);
          if (res.status === 200) {
            return (this.totalCount = res.data.count);
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Not Found`,
              icon: "XCircleIcon",
              variant: "danger",
              text: `${err}`,
            },
          });
        });
    },
    fetchData() {
      this.loading = true;

      // รวม Query
      this.whereCondition = `${
        this.whereLimit && this.whereLimit !== "" ? this.whereLimit : ""
      }${
        this.whereSortCondition && this.whereSortCondition !== ""
          ? this.whereSortCondition
          : ""
      }${
        this.wherePagination && this.wherePagination !== ""
          ? this.wherePagination
          : ""
      }${
        this.whereSearchCondition && this.whereSearchCondition !== ""
          ? this.whereSearchCondition
          : ""
      }${
        this.whereQueryDefaultCondition &&
        this.whereQueryDefaultCondition !== ""
          ? this.whereQueryDefaultCondition
          : ""
      }`;

      console.log("where con", this.whereCondition);

      return useJwt
        .query(this.collectionAPI, this.whereCondition)
        .then((res) => {
          if (res.status === 200) {
            console.log("res", res);
            return res && res.data && res.data.data && res.data.data.length > 0
              ? (this.accountList = res.data.data.map((item, index) => {
                  return {
                    id: item.id ? item.id : "",
                    c_code: item.c_code ? item.c_code : "",
                    c_username: item.c_username ? item.c_username : "",
                    c_accountName: item.c_accountName ? item.c_accountName : "",
                    c_mobile: item.c_mobile ? item.c_mobile : "",
                    b_symbol: item.b_symbol ? item.b_symbol : "",
                    b_name: item.b_name ? item.b_name : "",
                    BankAccountNumber: item.BankAccountNumber
                      ? item.BankAccountNumber
                      : "",
                    OwnName: item.OwnName ? item.OwnName : "",
                  };
                }))
              : (this.accountList = []);
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: err,
            },
          });
        })
        .finally(() => {
          this.loading = false;
          console.log("this.accountList", this.accountList);
          console.log("pageLength", this.pageLength);
        });
    },
    fetchOption() {
      useJwt
        // .query(this.collectionBankAPI, "&ba_name=โอนเข้า")
        .query(this.collectionBankAPI, "")
        .then((res) => {
          if (res.status === 200) {
            res && res.data && res.data.length > 0
              ? res.data.map((item, index) => {
                  if (item.ba_name.includes("โอนเข้า")) {
                    this.optionBank.push(item);
                  }
                })
              : (this.optionBank = []);
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: err,
            },
          });
        })
        .finally(() => {
          console.log("optionBank", this.optionBank);
        });
    },
    searchSubmit(e) {
      this.loading = true;
      const searchData = JSON.parse(JSON.stringify(e));
      const c_username =
        searchData && searchData.c_username
          ? `c_username=${searchData.c_username}`
          : "";
      // ต้องเรียงเอง
      this.whereSearchCondition = `${c_username ? `&${c_username}` : ""}`;

      this.wherePagination = "";

      // this.getDataTotal().then(() => {
      //   this.fetchData();
      // });
      this.fetchData().then((v) => {
        console.log("vv", v);
        if (c_username === "") {
          this.getDataTotal();
        } else {
          this.totalCount = v.length;
        }
      });
    },
    itemPerpageFunc(data) {
      this.loading = true;
      this.whereLimit = `&_limit=${data.currentPerPage}`;
      this.wherePagination = "";

      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    paginationFunc(data) {
      this.loading = true;
      if (data.currentPage > 1) {
        this.wherePagination = `&_start=${(data.currentPage - 1) *
          this.pageLength}`;
      } else {
        this.wherePagination = "";
      }

      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    sortFunc(data) {
      this.loading = true;
      this.whereSortCondition = "&_sort=";
      for (let i = 0; i < data.length; i++) {
        if (data.length === 1 || i === data.length - 1) {
          this.whereSortCondition =
            this.whereSortCondition + `${data[i].field}:${data[i].type}`;
        } else if (i < data.length - 1) {
          this.whereSortCondition =
            this.whereSortCondition + `${data[i].field}:${data[i].type}` + ",";
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Unsuccessful`,
              icon: "XCircleIcon",
              variant: "danger",
              text: "เกิดเหตุการณ์ผิดปกติ กรุณา refresh หน้าจอ",
            },
          });
          return false;
        }
      }
      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    showModal() {
      this.modalShow = true;
    },
    closeModal() {
      this.resetModal();
      this.modalShow = false;
    },
    resetModal() {
      this.selectedBank = [];
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.btnLoading = true;
      if (!this.selectedBank || this.selectedBank.length === 0) {
        this.selectError = true;
        this.btnLoading = false;
      } else {
        var error = 0;
        // this.btnLoading = true;
        if (this.selectedRow && this.selectedRow.length > 0) {
          Promise.all(
            this.selectedRow.map((item) => {
              const payload = {
                bid: this.selectedBank.BID,
                c_code: item.c_code,
              };
              return this.saveData(payload);
            })
          ).then((res) => {
            console.log("res promise", res);
            if (res.indexOf(false) !== -1) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: "มีข้อมูลที่เกิดข้อผิดพลาด",
                },
              });
              this.fetchData();
              this.btnLoading = false;
              this.closeModal()          
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "สำเร็จ",
                  icon: "BellIcon",
                  variant: "success",
                  text: "บันทึกข้อมูลสำเร็จ",
                },
              });
              this.fetchData();
              this.btnLoading = false;
              this.closeModal()
            }
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: "เกิดข้อผิดพลาดในการเลือกข้อมูล",
            },
          });
        }
      } // else
    },
    saveData(payload) {
      return useJwt
        .put(this.collectionAPI, payload)
        .then((res) => {
          if (res.data.response === true) {
            console.log("res from user", res);
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          return false;
        });
      // console.log("loading", this.btnLoading);
    },
    optionSearch(options, search) {
      console.log('options',options,'search',search);

      if(search.length){
        console.log('search length',search.length);
        const result = options.filter(item => item.OwnName.indexOf(search) !== -1)
        return result
      } else {
        console.log('options',options);
        return options
      }
    },
  },
};
</script>
